import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Image from '../components/image'

const IndexPage = () => (
	<Layout>
		<SEO title='Home' />
		<h2>Under construction...</h2>
		<p>Thanks for stopping by, I'll have a bit more up here soon.</p>
		<p>In the meantime, here's a sunflower.</p>
		<div className='sunflower'>
			<Image className='sunflower' />
		</div>
	</Layout>
)

export default IndexPage
